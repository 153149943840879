/* eslint-disable no-unused-vars */
/**
 * Example JSON 
 *  {
 *    "district": "คลองท่อมเหนือ",
 *    "amphoe": "คลองท่อม",
 *    "province": "กระบี่",
 *    "zipcode": 81120,
 *    "district_code": 810402,
 *    "amphoe_code": 8104,
 *    "province_code": 81
 *   },
 */
import json from '../assets/data/address.json';

const filterdAddress = (data) => {
    return new Promise((resolve, reject) => {
        const result = json.filter(a => {
            let result = false;
            result = result || (data.district ? a.district.includes(data.district) : false);
            result = result || (data.amphoe ? a.amphoe.includes(data.amphoe) : false);
            result = result || (data.province ? a.province.includes(data.province) : false);
            result = result || (data.zipcode ? String(a.zipcode) == data.zipcode : false);
            return result;
        }).map(a => toText(a));
        resolve(result);
    });
};

const toText = (json) => {
    const a1 = 10 == json.province_code ? 'แขวง' : 'ตำบล';
    const a2 = 10 == json.province_code ? 'เขต' : 'อำเภอ';
    return `${a1}${json.district}, ${a2}${json.amphoe}, จังหวัด${json.province}, ${json.zipcode}`;
};

export { filterdAddress };