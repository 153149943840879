<template>
    <div class="mt-n6 div-profile">
        <div class="pt-10 d-block">
            <label class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2" style="border-bottom: 1px solid;">
                แก้ไขข้อมูลส่วนตัว
            </label>
        </div>
        <v-container class="pt-15 pb-16" v-resize="onResize" :style="{width: windowSize.w + 'px'}">
            <v-form v-model="isValid" ref="profileForm" class="form-profile">
                <label class="pt-8 pl-8 d-block font-weight-bold white--text text-h6 text-left">ข้อมูลส่วนตัว</label>
                <label class="pl-8 d-block white--text text-body1 text-left">ประเภท: {{ typeText }}</label>
                <div class="d-block ml-8 mt-5">
                    <v-text-field v-model="form.name" dense dark outlined color="yellow darken-3" label="ชื่อ - นามสกุล"
                        placeholder="ชื่อ - นามสกุล" :rules="requiredRules">
                    </v-text-field>
                </div>
                <div class="d-flex ml-8">
                    <v-text-field v-model="form.mobile" dense dark outlined color="yellow darken-3" label="เบอร์โทร"
                        placeholder="เบอร์โทร" :rules="mobileRules">
                    </v-text-field>
                    <v-text-field v-model="form.email" class="ml-5" readonly dense dark outlined color="yellow darken-3"
                        label="อีเมล" placeholder="อีเมล">
                    </v-text-field>
                </div>
                <div class="d-block ml-8">
                    <v-text-field maxlength="13" v-model="form.idTax" dense dark outlined color="yellow darken-3"
                        label="หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี" :rules="taxRules"></v-text-field>
                </div>
                <div class="d-block ml-8">
                    <v-text-field dense dark outlined color="yellow darken-3" label="ทีอยู่" placeholder="ที่อยู่"
                        v-model="addressText" @click="$refs.dialogAddressForm.show(form.address)" :rules="addressRules">
                    </v-text-field>
                </div>
                <v-btn rounded color="yellow darken-3" class="px-10 mt-4" @click="update">
                    บันทึก
                </v-btn>
            </v-form>
        </v-container>
        <dialog-address-form noRules @confirm="dialogAddressConfirm" ref="dialogAddressForm" />
        <dialog-error ref="dialogValidateFail" :topic="'ข้อมูลไม่ถูกต้อง'" :text="'กรุณากรอกข้อมูลใหม่อีกครั้ง'" />
        <dialog-error ref="dialogError" :topic="'Error'" :text="errorMessage" />
        <dialog-success ref="dialogSuccess" :text="'บันทึกเรียบร้อยแล้ว'" :redirectTo="null" />
    </div>
</template>

<script>
import DialogAddressForm from '../components/DialogAddressForm.vue';
import { requiredRules, mobileRules, emailRules, taxRules } from '../utils/validation-rule';
import DialogError from '../components/DialogError.vue';
import DialogSuccess from '../components/DialogSuccess.vue';
import { getMyProfile, updateMyProfile } from '../models/customer';

export default {
    data: () => ({
        height: 0,
        width: 0,
        initForm: {},
        isValid: false,
        form: {
            name: '',
            mobile: '',
            email: '',
            address: {
                address1: '',
                address2: '',
                lat: '',
                lng: ''
            }
        },
        addressText: '',
        windowSize: {},
        addressRules: [
            v => v != null && v.length > 5 || 'โปรดกรอกที่อยู่' 
        ],
        requiredRules,
        mobileRules,
        emailRules,
        taxRules,
        errorMessage: ''
    }),
    methods: {
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        dialogAddressConfirm(data){
            this.form.address = data;
            this.addressText = this.form.address.address2 + ' ' + this.form.address.address1;
        },
        async update(){
            // get profile and update
            this.$refs.profileForm.validate();
            if(!this.isValid){
                this.$refs.dialogValidateFail.show();
            }else{
                const loader = this.$loading.show();
                try{
                    await updateMyProfile(this.form);
                    this.$refs.dialogSuccess.showThenRefresh();
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
            }
        }
    },
    computed: {
        typeText() {
            return 'person' == this.form.type ? 'บุคคลธรรมดา' : 'นิติบุคคล';
        }
    },
    async mounted(){
        const loader = this.$loading.show();
        try{
            const response = await getMyProfile();
            this.form = response.data;
            this.addressText = this.form.address.address2 + ' ' + this.form.address.address1;
        }catch(error){
            this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
            this.$refs.dialogError.show();
        }finally{
            loader.hide();
        }
    },
    watch: {
    },
    components: {
        DialogAddressForm,
        DialogError,
        DialogSuccess
    }
};
</script>

<style scoped>
    .div-profile{
        text-align: center;
    }
    .form-profile{
        background-color: #0F0E0D;
        border-radius: 8px;
        padding-right: 32px;
        padding-bottom: 32px;
    }
    
</style>

<style>
    .v-radio .v-icon {
        background-image: radial-gradient(#cccccc 40%, transparent 10%);
    }
</style>